<template>
    <div class="w-full h-full flex flex-col">
        <Header>
            <div>
                <h1>Dashboards</h1>
                <h3 v-if="dashboards">Totalt {{dashboards.length}} {{dashboards.length > 1 ? 'dashboards' : 'dashboard'}}</h3>
            </div>
        </Header>
        
        <div class="flex-grow overflow-auto p-6 md:p-12">
            <div 
                v-if="dashboards"
                class="
                    grid
                    gap-6 
                    md:gap-12
                    grid-cols-1
                    lg:grid-cols-2
                    xl:grid-cols-3
                ">
                
                <a 
                    v-for="d in dashboards"
                    :key="'dashboard_'+d.dashboardId"
                    @click="dashboardEdit = d">
                    
                    <DashboardCard 
                        :dashboard="d"
                    />
                </a>
            </div>
        </div>
        
        <div class="absolute bottom-0 right-0 m-8">
            <button 
                @click="showDashboardNew = true"
                class="button submit round">
                
                <i class="fas fa-plus" />
            </button>
        </div>
        
        <Modal 
            v-if="showDashboardNew"
            @close="showDashboardNew = false"
            insideClasses="bg-white">
            
            <header slot="header">
                Ny dashboard
            </header>
            
            <form 
                @submit.prevent="onCreateDashboard()"
                class="py-2 px-6">
                
                <InputSingleline 
                    v-model="dashboardNew.name"
                    required="true"
                    label="Navn på Dashboard"
                    name="name"
                    showRequiredWarning="true"
                    ref="autofocus"
                />
            </form>
            
            <footer slot="footer">
                <div class="space-x-4 text-right">
                    <a 
                        class="button" 
                        @click="showDashboardNew = false">
                        
                        Avbryt
                    </a>
                    
                    <button 
                        type="submit"
                        @click.prevent="onCreateDashboard()" 
                        :disabled="loading == 'creating'"
                        class="button submit"
                        :class="{'loading' : loading == 'creating'}">
                        
                        Opprett
                    </button>
                </div>
            </footer>
        </Modal>
        
        <div 
            v-if="dashboardEdit"
            class="absolute inset-0 flex">
            
            <div 
                @click="dashboardEdit = null"
                class="flex-grow bg-black opacity-25 ">
                
            </div>
            
            <transition name="fade">
                <DashboardEdit 
                    :dashboard="dashboardEdit" 
                    @close="dashboardEdit = null"
                    class="
                        absolute bg-white right-0 overflow-hidden w-full
                        md:rounded-l-lg 
                        md:w-150
                        md:shadow-2xl
                    "
                />
            </transition>
        </div>
    </div>
</template>

<script>
    export default {
        components: {
            'DashboardEdit': () => import('@/components/dashboards/DashboardEdit.vue'),
            'DashboardCard': () => import('@/components/dashboards/DashboardCard.vue'),
        },
        
        computed:{
            dashboards(){
                return this.$store.getters.getDashboards;
            },
        },
        
        data(){
            return {
                dashboardEdit: null,
                dashboardNew: {
                    name: null,
                    widgets: null,
                },
                showDashboardNew: false,
                loading: null,
            }
        },
        
        methods: {
            async onCreateDashboard(){
                try {
                    if ( !this.dashboardNew.name ) {
                        throw Error('En dashboard må ha et navn');
                    }
                    
                    this.loading = 'creating';
                    const dashboard = await this.$store.dispatch('createDashboard', this.dashboardNew);
                    console.log('dashboard response', dashboard);
                    
                    this.dashboardNew = {
                        name: null,
                        widgets: null,
                    };
                    
                    this.showDashboardNew = false;
                    this.dashboardEdit = dashboard;
                } 
                catch (e) {
                    this.$notify({ 
                        type: 'error', 
                        text: e.message || 'Kunne ikke opprette dashboard'
                    });
                    
                    console.error(e);
                } 
                finally {
                    this.loading = null;
                }
            },
        },
        
        async mounted(){
            await this.$store.dispatch('listDashboards');
        },
    }
</script>

<style lang="scss" >

</style>
